export default {
  jahre: {
    max: {
      characters: 12,
      message: {
        de: 'Der Text darf maximal 12 Zeichen lang sein.',
      },
    },
  },
  lehrtaetigkeit: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
  },
}
