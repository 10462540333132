<template>
  <div>
    <h1>Spezialisierungen</h1>

    <ListTable v-if="spezialisierungen">
      <thead v-if="spezialisierungen.length > 0">
        <tr>
          <th>Spezialisierung</th>
          <th></th>
          <th><OrderIcon css="height:2rem;fill:white;" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'spezialisierungen')">
        <tr v-for="({ id, titel }, i) in spezialisierungen" :key="id">
          <TdContent>{{ titel }}</TdContent>
          <td>
            <TableButtonEdit
              :to="{ name: 'SpezialisierungenEdit', params: { spezialisierungId: id } }"
            >
              Bearbeiten
            </TableButtonEdit>
          </td>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'spezialisierungen')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'SpezialisierungenCreate' }" :colspan="3">
            Spezialisierung hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import TableComponents from '@/components/TableComponents'

import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'SpezialisierungenIndex',
  components: {
    ...TableComponents,
    OrderIcon,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('spezialisierungen/getAll')
    next()
  },
  computed: {
    spezialisierungen: () => store.getters['spezialisierungen/all'],
  },
  methods: {
    setOrder(payload, model) {
      this.$store.dispatch(`${model}/setPosition`, payload)
    },
    onOrderEnd(event, model) {
      const id = this[model][event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch(`${model}/setPosition`, { id, position })
    },
  },
}
</script>

<style scoped></style>
