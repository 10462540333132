<template>
  <div>
    <h1>Methoden</h1>

    <h2>Einleitung</h2>

    <content-box class="contentBox" v-html="methoden.einleitung" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Psychodrama</h2>

    <content-box class="contentBox" v-html="methoden.psychodrama" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Hypnotherapie</h2>

    <content-box class="contentBox" v-html="methoden.hypnotherapie" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'MethodenEdit' }">
      BEARBEITEN
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'MethodenIndex',
  components: {
    ContentBox,
    VButton,
    VSpacer,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('methoden/get')
    next()
  },
  computed: {
    methoden: () => store.getters['methoden/methoden'],
  },
}
</script>
