<template>
  <div>
    <GoBack :to="{ name: 'SupervisionUndCoachingIndex' }">Supervision und Coaching</GoBack>

    <h1>Supervision und Coaching bearbeiten</h1>

    <VForm @submit="submit">
      <h2 class="form-heading">Psychotherapeutisches Coaching sowie Supervision</h2>
      <FormRowEditor
        v-model="formData.supervision"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <h2 class="form-heading">Businesscoaching</h2>
      <FormRowEditor
        v-model="formData.businesscoaching"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <h2 class="form-heading">Work-Life-Balance und Burnout-Prävention</h2>
      <FormRowEditor
        v-model="formData.work_life_balance"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <h2 class="form-heading">Vorbereitung auf Bewerbungs- und Prüfungssituationen</h2>
      <FormRowEditor
        v-model="formData.vorbereitung"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'SupervisionUndCoachingIndex' }" mode="faint">
          ABBRECHEN
        </VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'SupervisionUndCoachingEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: null,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('supervisionUndCoaching/get')
    next()
  },
  created() {
    this.formData = {
      supervision: this.supervisionUndCoaching.supervision,
      businesscoaching: this.supervisionUndCoaching.businesscoaching,
      work_life_balance: this.supervisionUndCoaching.workLifeBalance,
      vorbereitung: this.supervisionUndCoaching.vorbereitung,
    }
  },
  computed: {
    supervisionUndCoaching: () => store.getters['supervisionUndCoaching/supervisionUndCoaching'],
  },
  methods: {
    async submit() {
      this.submitted = true
      await this.$store.dispatch('supervisionUndCoaching/update', this.formData)
      this.$router.push({ name: 'SupervisionUndCoachingIndex' })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-heading {
  margin-bottom: 0;
}

.form-heading:not(:first-of-type) {
  margin-top: 4rem;
}
</style>
