<template>
  <span class="info">
    <button type="button" class="info-button" @click="showInfoModal">?</button>

    <VModal ref="infoModal">
      <template v-if="title" slot="title">{{ title }}</template>
      <p>
        <slot></slot>
      </p>
    </VModal>
  </span>
</template>

<script>
import VModal from '@/components/global/Modal'

export default {
  name: 'FormInfo',
  components: {
    VModal,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  methods: {
    showInfoModal() {
      this.$refs.infoModal.open()
    },
  },
}
</script>

<style lang="scss" scoped>
.info {
  position: relative;
}

.info-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 1.25em;
  width: 1.25em;
  margin-left: 0.375em;
  font-size: 1em;
  color: $col-white;
  font-weight: $weight-semibold;
  background-color: $col-gray-300;
  border-radius: 50%;
}

p {
  line-height: 1.25;
  font-weight: $weight-semilight;
}
</style>
