<template>
  <div>
    <h1>Home</h1>

    <h2>Über Mich</h2>

    <content-box class="contentBox" v-html="home.ueberMich" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Psychotherapie</h2>

    <content-box class="contentBox" v-html="home.psychotherapie" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Spezialisierungen</h2>

    <content-box class="contentBox" v-html="home.spezialisierungen" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Methoden</h2>

    <content-box class="contentBox" v-html="home.methoden" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Settings und Kosten</h2>

    <content-box class="contentBox" v-html="home.settingsUndKosten" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Angebote</h2>

    <content-box class="contentBox" v-html="home.angebote" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Angststörungen</h2>

    <content-box class="contentBox" v-html="home.angststoerungen" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Selbsterfahrung</h2>

    <content-box class="contentBox" v-html="home.selbsterfahrung" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <h2>Supervision und Coaching</h2>

    <content-box
      class="contentBox"
      v-html="home.supervisionUndCoaching"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'HomeEdit' }">
      BEARBEITEN
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'HomeIndex',
  components: {
    ContentBox,
    VButton,
    VSpacer,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('home/get')
    next()
  },
  computed: {
    home: () => store.getters['home/home'],
  },
}
</script>

<style scoped>
.contentBox {
  white-space: pre-wrap;
}
</style>
