import axios from 'axios'

const getDefaultState = () => ({
  methoden: {},
})

const state = getDefaultState()

const getters = {
  methoden: state => state.methoden,
}

const mutations = {
  SET: (state, methoden) => (state.methoden = methoden),
}

const actions = {
  get: async ({ commit }) => {
    const url = '/methoden'
    const res = await axios.get(url)
    commit('SET', res.data.data)
    return res.data.data
  },

  update: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/methoden'
      const res = await axios.patch(url, formData)
      commit('SET', res.data.data)
      dispatch('flashMessage/flashGespeichert', null, { root: true })
      return res.data.data
    } catch (err) {
      dispatch('flashMessage/flashSpeichernFehlgeschlagen', null, { root: true })
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
