<template>
  <div>
    <GoBack :to="{ name: 'UeberMichIndex' }">Über Mich</GoBack>

    <h1>Lehrtätigkeit bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="jahre"
          label="Zeitraum"
          fieldWidth="32rem"
          v-model="formData.jahre"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldTextarea
          field="lehrtaetigkeit"
          label="Lehrtätigkeit*"
          fieldWidth="32rem"
          :rows="3"
          v-model="formData.lehrtaetigkeit"
          :errors="formErrors"
          :rules="validationRules"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'UeberMichIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>

      <FormRowButtons>
        <VButton type="button" mode="danger" @click="$refs.deleteModal.open()">
          LÖSCHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <ModalConfirm ref="deleteModal" @confirm="deleteLehrtaetigkeit">
      Sind Sie sicher, dass Sie diese Lehrtätigkeit löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/lehrtaetigkeiten'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import ModalConfirm from '@/components/global/ModalConfirm'

export default {
  name: 'LehrtaetigkeitenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    ModalConfirm,
  },
  props: ['lehrtaetigkeitId'],
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('lehrtaetigkeiten/getOne', to.params.lehrtaetigkeitId)
    next()
  },
  created() {
    this.formData = {
      jahre: this.lehrtaetigkeit.jahre ?? '',
      lehrtaetigkeit: this.lehrtaetigkeit.lehrtaetigkeit,
    }
  },
  computed: {
    lehrtaetigkeit: () => store.getters['lehrtaetigkeiten/one'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('lehrtaetigkeiten/update', {
        id: this.lehrtaetigkeitId,
        formData: this.formData,
      })
      this.$router.push({ name: 'UeberMichIndex' })
    },
    async deleteLehrtaetigkeit() {
      await this.$store.dispatch('lehrtaetigkeiten/delete', this.lehrtaetigkeitId)
      this.$router.push({ name: 'UeberMichIndex' })
    },
  },
}
</script>
