export default {
  ueber_mich: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 500,
      message: {
        de: 'Der Text darf maximal 500 Zeichen lang sein.',
      },
    },
  },
  psychotherapie: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 500,
      message: {
        de: 'Der Text darf maximal 500 Zeichen lang sein.',
      },
    },
  },
  spezialisierungen: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 200,
      message: {
        de: 'Der Text darf maximal 200 Zeichen lang sein.',
      },
    },
  },
  methoden: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 200,
      message: {
        de: 'Der Text darf maximal 200 Zeichen lang sein.',
      },
    },
  },
  settings_und_kosten: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 200,
      message: {
        de: 'Der Text darf maximal 200 Zeichen lang sein.',
      },
    },
  },
  angebote: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 500,
      message: {
        de: 'Der Text darf maximal 500 Zeichen lang sein.',
      },
    },
  },
  angststoerungen: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 200,
      message: {
        de: 'Der Text darf maximal 200 Zeichen lang sein.',
      },
    },
  },
  selbsterfahrung: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 200,
      message: {
        de: 'Der Text darf maximal 200 Zeichen lang sein.',
      },
    },
  },
  supervision_und_coaching: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 200,
      message: {
        de: 'Der Text darf maximal 200 Zeichen lang sein.',
      },
    },
  },
}
