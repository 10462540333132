<template>
  <router-link class="addItem" :to="to" tag="td" :colspan="colspan">
    <div class="wrap">
      <plus-icon ref="plusIcon" class="plusIcon"></plus-icon>
      <span class="text"><slot>Add Item</slot></span>
    </div>
  </router-link>
</template>

<script>
import PlusIcon from '@/components/cmsIcons/PlusIcon'

export default {
  name: 'TableAddItem',
  components: {
    PlusIcon,
  },
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    colspan: {
      type: Number,
      default: 1,
    },
  },
}
</script>

<style lang="scss" scoped>
.addItem {
  display: table-cell flex;
  cursor: pointer;
  vertical-align: center;
  padding: 0;
  background: transparent;
  border: 1px solid $col-gray-300;

  &:hover {
    background: $col-gray-200;
  }
}

.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.plusIcon {
  display: inline-block;
  height: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  ::v-deep * {
    fill: $col-gray-700;
  }
}

.text {
  font-size: 0.875rem;
  font-weight: $weight-bold;
  letter-spacing: $tracking-wide;
  color: $col-gray-700;
  padding-right: 1rem;
}
</style>
