export default {
  validateField(field, value, errors, rules) {
    this.resetError(field, errors)
    if (rules.if ? rules.if() : true) {
      if (!value) {
        if (rules.required) {
          this.setError(field, errors, rules.required.message)
        }
      } else {
        if (rules.min && value.length > 0 && value.length < rules.min.characters) {
          this.setError(field, errors, rules.min.message)
        } else if (rules.max && value.length > rules.max.characters) {
          this.setError(field, errors, rules.max.message)
        } else if (
          rules.email &&
          value.length > 0 &&
          /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) == false
        ) {
          this.setError(field, errors, rules.email.message)
        }
      }
    }
  },
  validateAll(formdata, errors, allRules) {
    Object.entries(formdata).forEach(([field, value]) => {
      this.validateField(field, value, errors, allRules[field])
    })
  },
  hasError(field, errors) {
    return errors.some(err => err.field == field)
  },
  getError(field, errors) {
    return errors.find(err => err.field == field)
  },
  getErrorMessage(field, errors, lang = 'de') {
    return this.getError(field, errors)?.message[lang]
  },
  setError(field, errors, message) {
    if (!this.hasError(field, errors)) {
      // note:
      // when the following line is executed
      // other events (like click on "Abbrechen") don't fire
      errors.push({ field, message })
    }
  },
  resetError(field, errors) {
    if (this.hasError(field, errors)) {
      errors.splice(
        errors.findIndex(err => err.field == field),
        1
      )
    }
  },
}
