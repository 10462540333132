<template>
  <div>
    <GoBack :to="{ name: 'SpezialisierungenIndex' }">Spezialisierungen</GoBack>

    <h1>Spezialisierung anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="titel"
          label="Spezialisierung*"
          fieldWidth="32rem"
          v-model="formData.titel"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormRowFields>
          <FormLabelInline width="24rem" style="margin-top: 4.5rem" for="beschreibung">
            Beschreibung*
          </FormLabelInline>
          <FormRowEditor
            v-model="formData.beschreibung"
            :features="['bold', 'italic', 'unorderedList']"
          />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'SpezialisierungenIndex' }" mode="faint">
          ABBRECHEN
        </VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/spezialisierungen'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'SpezialisierungenCreate',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: {
        titel: '',
        beschreibung: '<p></p>',
      },
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('spezialisierungen/create', this.formData)
      this.$router.push({ name: 'SpezialisierungenIndex' })
    },
  },
}
</script>
