<template>
  <div v-if="modalVisible" class="modal-component">
    <div class="overlay" @click="close"></div>

    <div class="modal">
      <h3>
        <slot name="title"></slot>
      </h3>
      <slot></slot>
      <button class="close" @click="close">
        <x-icon>Popup schließen</x-icon>
      </button>
    </div>
  </div>
</template>

<script>
import XIcon from '@/components/cmsIcons/XIcon'

export default {
  name: 'Modal',
  components: {
    XIcon,
  },
  props: {
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalVisible: false,
    }
  },
  methods: {
    handleGlobalKeyupEvent() {
      if (event.keyCode === 27) this.close()
    },
    open() {
      this.modalVisible = true
      if (this.closeOnEsc) {
        document.addEventListener('keyup', this.handleGlobalKeyupEvent)
      }
      this.emitOpen()
    },
    close() {
      this.modalVisible = false
      if (this.closeOnEsc) {
        document.removeEventListener('keyup', this.handleGlobalKeyupEvent)
      }
      this.emitClose()
    },
    emitOpen() {
      this.$emit('open')
    },
    emitClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-component {
  z-index: 600;

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .modal {
    position: fixed;
    max-height: calc(100vh - 8rem);
    max-width: calc(100vw - 8rem);
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    padding: 3rem 6rem;
    text-align: left;
    background: $col-white;
    border-radius: $modal-border-radius;
    box-shadow: 4px 16px 24px rgba(0, 0, 0, 0.2);
    z-index: 2;

    h3 {
      margin-bottom: 3rem;
      font-family: $font;
      font-size: $modal-font-size;
      font-weight: $modal-font-weight;
      letter-spacing: $modal-tracking;
      text-align: center;
    }

    .close {
      position: absolute;
      height: 2rem;
      top: 1rem;
      right: 1rem;
      border-radius: 1rem;

      svg {
        height: 100%;
      }

      &:hover {
        background: $col-gray-100;
      }
    }
  }
}
</style>
