<template>
  <div>
    <h1>Supervision und Coaching</h1>

    <h2>Psychotherapeutisches Coaching sowie Supervision</h2>

    <content-box
      class="contentBox"
      v-html="supervisionUndCoaching.supervision"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <h2>Businesscoaching</h2>

    <content-box
      class="contentBox"
      v-html="supervisionUndCoaching.businesscoaching"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <h2>Work-Life-Balance und Burnout-Prävention</h2>

    <content-box
      class="contentBox"
      v-html="supervisionUndCoaching.workLifeBalance"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <h2>Vorbereitung auf Bewerbungs- und Prüfungssituationen</h2>

    <content-box
      class="contentBox"
      v-html="supervisionUndCoaching.vorbereitung"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'SupervisionUndCoachingEdit' }">
      BEARBEITEN
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'SupervisionUndCoachingIndex',
  components: {
    ContentBox,
    VButton,
    VSpacer,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('supervisionUndCoaching/get')
    next()
  },
  computed: {
    supervisionUndCoaching: () => store.getters['supervisionUndCoaching/supervisionUndCoaching'],
  },
}
</script>
