<template>
  <v-modal ref="modal">
    <template v-slot:title><slot></slot></template>

    <div class="buttons">
      <v-button class="confirm" mode="danger" @click="confirm">
        <slot name="confirm">JA</slot>
      </v-button>
      <v-button class="cancel" mode="faint" @click="close">
        <slot name="cancel">NEIN</slot>
      </v-button>
    </div>
  </v-modal>
</template>

<script>
import Modal from './Modal'
import Button from '@/components/global/Button'

export default {
  name: 'ModalConfirm',
  components: {
    'v-modal': Modal,
    'v-button': Button,
  },
  methods: {
    open() {
      this.$refs.modal.open()
    },
    close() {
      this.$refs.modal.close()
    },
    confirm() {
      this.$refs.modal.close()
      this.$emit('confirm')
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons {
  text-align: center;
}

.cancel {
  margin-left: 2rem;
}
</style>
