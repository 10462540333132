<template>
  <div class="layout--auth">
    <main>
      <router-view></router-view>
    </main>

    <v-menu></v-menu>
    <flash-message></flash-message>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import FlashMessage from '@/components/global/FlashMessage'

export default {
  name: 'Auth',
  components: {
    ...Menu,
    FlashMessage,
  },
}
</script>

<style lang="scss">
// @import '@/assets/scss/app.scss';

.layout--auth {
  position: relative;
  min-height: 100vh;
  background: $body-bg;
  padding: 3rem 1.5rem 6rem;

  @include desktop {
    padding: 4rem 2rem 8rem calc(#{$nav-width} + 2rem);
  }
}
</style>
