<template>
  <div>
    <h1>Über Mich</h1>

    <h2>Einleitung</h2>

    <content-box v-html="ueberMich.einleitung" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'UeberMichEinleitungEdit' }">
      BEARBEITEN
    </VButton>

    <VSpacer />

    <h2>Berufliches</h2>

    <content-box v-html="ueberMich.berufliches" maxWidth="40rem"></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'UeberMichBeruflichesEdit' }">
      BEARBEITEN
    </VButton>

    <VSpacer />

    <h2>Ausbildung</h2>

    <ListTable v-if="ausbildungen">
      <thead v-if="ausbildungen.length > 0">
        <tr>
          <th>Jahr</th>
          <th>Ausbildung</th>
          <th></th>
          <th><OrderIcon css="height:2rem;fill:white;" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'ausbildungen')">
        <tr v-for="({ id, jahre, ausbildung }, i) in ausbildungen" :key="id">
          <TdContent css="min-width: 8rem">{{ jahre }}</TdContent>
          <TdContent>{{ ausbildung }}</TdContent>
          <td>
            <TableButtonEdit :to="{ name: 'AusbildungenEdit', params: { ausbildungId: id } }">
              Bearbeiten
            </TableButtonEdit>
          </td>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'ausbildungen')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'AusbildungenCreate' }" :colspan="4">
            Ausbildung hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <VSpacer />

    <h2>Lehrtätigkeit</h2>

    <ListTable v-if="lehrtaetigkeiten">
      <thead v-if="lehrtaetigkeiten.length > 0">
        <tr>
          <th>Jahr</th>
          <th>Lehrtätigkeit</th>
          <th></th>
          <th><OrderIcon css="height:2rem;fill:white;" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'lehrtaetigkeiten')">
        <tr v-for="({ id, jahre, lehrtaetigkeit }, i) in lehrtaetigkeiten" :key="id">
          <TdContent css="min-width: 8rem">{{ jahre }}</TdContent>
          <TdContent>{{ lehrtaetigkeit }}</TdContent>
          <td>
            <TableButtonEdit
              :to="{ name: 'LehrtaetigkeitenEdit', params: { lehrtaetigkeitId: id } }"
            >
              Bearbeiten
            </TableButtonEdit>
          </td>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'lehrtaetigkeiten')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'LehrtaetigkeitenCreate' }" :colspan="4">
            Lehrtätigkeit hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <VSpacer />

    <h2>Publikationen</h2>

    <ListTable v-if="publikationen">
      <thead v-if="publikationen.length > 0">
        <tr>
          <th>Publikation</th>
          <th></th>
          <th><OrderIcon css="height:2rem;fill:white;" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'publikationen')">
        <tr v-for="({ id, publikation }, i) in publikationen" :key="id">
          <TdContent class="cms" v-html="publikation"></TdContent>
          <td>
            <TableButtonEdit :to="{ name: 'PublikationenEdit', params: { publikationId: id } }">
              Bearbeiten
            </TableButtonEdit>
          </td>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'publikationen')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'PublikationenCreate' }" :colspan="4">
            Publikation hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <VSpacer />

    <h2>Kooperationspartner</h2>

    <ListTable v-if="kooperationspartner">
      <thead v-if="kooperationspartner.length > 0">
        <tr>
          <th>Kooperationspartner</th>
          <th></th>
          <th><OrderIcon css="height:2rem;fill:white;" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'kooperationspartner')">
        <tr v-for="({ id, kooperationspartner }, i) in kooperationspartner" :key="id">
          <TdContent class="cms" v-html="kooperationspartner"></TdContent>
          <td>
            <TableButtonEdit
              :to="{ name: 'KooperationspartnerEdit', params: { kooperationspartnerId: id } }"
            >
              Bearbeiten
            </TableButtonEdit>
          </td>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'kooperationspartner')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'KooperationspartnerCreate' }" :colspan="4">
            Kooperationspartner hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>

    <VSpacer />

    <h2>Ehrenamtliche Tätigkeit</h2>

    <ListTable v-if="ehrenamtlicheTaetigkeiten">
      <thead v-if="ehrenamtlicheTaetigkeiten.length > 0">
        <tr>
          <th>Ausbildung</th>
          <th></th>
          <th><OrderIcon css="height:2rem;fill:white;" /></th>
        </tr>
      </thead>
      <TbodyDraggable @end="onOrderEnd($event, 'ehrenamtlicheTaetigkeiten')">
        <tr v-for="({ id, ehrenamtlicheTaetigkeit }, i) in ehrenamtlicheTaetigkeiten" :key="id">
          <TdContent class="cms" v-html="ehrenamtlicheTaetigkeit"></TdContent>
          <td>
            <TableButtonEdit
              :to="{
                name: 'EhrenamtlicheTaetigkeitenEdit',
                params: { ehrenamtlicheTaetigkeitId: id },
              }"
            >
              Bearbeiten
            </TableButtonEdit>
          </td>
          <td>
            <TableOrder
              :order="i + 1"
              @submit="setOrder({ id, position: $event }, 'ehrenamtlicheTaetigkeiten')"
            />
          </td>
        </tr>
        <tr>
          <TableAddItem :to="{ name: 'EhrenamtlicheTaetigkeitenCreate' }" :colspan="4">
            Ehrenamtliche Tätigkeit hinzufügen
          </TableAddItem>
        </tr>
      </TbodyDraggable>
    </ListTable>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import TableComponents from '@/components/TableComponents'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

import OrderIcon from '@/components/cmsIcons/OrderIcon'

export default {
  name: 'UeberMichIndex',
  components: {
    ContentBox,
    ...TableComponents,
    VButton,
    VSpacer,
    OrderIcon,
  },
  async beforeRouteEnter(to, from, next) {
    const ueberMich = await store.dispatch('ueberMich/get')
    store.commit('ausbildungen/SET_ALL', ueberMich.ausbildungen)
    store.commit('lehrtaetigkeiten/SET_ALL', ueberMich.lehrtaetigkeiten)
    store.commit('publikationen/SET_ALL', ueberMich.publikationen)
    store.commit('kooperationspartner/SET_ALL', ueberMich.kooperationspartner)
    store.commit('ehrenamtlicheTaetigkeiten/SET_ALL', ueberMich.ehrenamtlicheTaetigkeiten)
    next()
  },
  computed: {
    ueberMich: () => store.getters['ueberMich/ueberMich'],
    ausbildungen: () => store.getters['ausbildungen/all'],
    lehrtaetigkeiten: () => store.getters['lehrtaetigkeiten/all'],
    publikationen: () => store.getters['publikationen/all'],
    kooperationspartner: () => store.getters['kooperationspartner/all'],
    ehrenamtlicheTaetigkeiten: () => store.getters['ehrenamtlicheTaetigkeiten/all'],
  },
  methods: {
    setOrder(payload, model) {
      this.$store.dispatch(`${model}/setPosition`, payload)
    },
    onOrderEnd(event, model) {
      const id = this[model][event.oldIndex].id
      const position = event.newIndex + 1
      this.$store.dispatch(`${model}/setPosition`, { id, position })
    },
  },
}
</script>

<style scoped></style>
