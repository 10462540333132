<template>
  <div>
    <GoBack :to="{ name: 'AngststoerungenIndex' }">Angststörungen</GoBack>

    <h1>Angststörungen bearbeiten</h1>

    <VForm @submit="submit">
      <h2 class="form-heading">Text vor Bild</h2>
      <FormRowEditor
        v-model="formData.text_vor_bild"
        :features="['bold', 'italic', 'underline', 'link', 'heading', 'unorderedList']"
      />

      <h2 class="form-heading">Text nach Bild</h2>
      <FormRowEditor
        v-model="formData.text_nach_bild"
        :features="['bold', 'italic', 'underline', 'link', 'heading', 'unorderedList']"
      />

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'AngststoerungenIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'AngststoerungenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: null,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('angststoerungen/get')
    next()
  },
  created() {
    this.formData = {
      text_vor_bild: this.angststoerungen.textVorBild,
      text_nach_bild: this.angststoerungen.textNachBild,
    }
  },
  computed: {
    angststoerungen: () => store.getters['angststoerungen/angststoerungen'],
  },
  methods: {
    async submit() {
      this.submitted = true
      await this.$store.dispatch('angststoerungen/update', this.formData)
      this.$router.push({ name: 'AngststoerungenIndex' })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-heading {
  margin-bottom: 0;
}

.form-heading:not(:first-of-type) {
  margin-top: 4rem;
}
</style>
