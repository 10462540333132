import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import flashMessage from './flashMessage'

import home from './home'
import ueberMich from './ueberMich'
import ausbildungen from './ausbildungen'
import lehrtaetigkeiten from './lehrtaetigkeiten'
import publikationen from './publikationen'
import kooperationspartner from './kooperationspartner'
import ehrenamtlicheTaetigkeiten from './ehrenamtlicheTaetigkeiten'
import spezialisierungen from './spezialisierungen'
import methoden from './methoden'
import settingsUndKosten from './settingsUndKosten'
import angststoerungen from './angststoerungen'
import selbsterfahrung from './selbsterfahrung'
import supervisionUndCoaching from './supervisionUndCoaching'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    flashMessage,
    home,
    ueberMich,
    ausbildungen,
    lehrtaetigkeiten,
    publikationen,
    kooperationspartner,
    ehrenamtlicheTaetigkeiten,
    spezialisierungen,
    methoden,
    settingsUndKosten,
    angststoerungen,
    selbsterfahrung,
    supervisionUndCoaching,
  },
})
