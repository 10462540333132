<template>
  <div>
    <h1>Selbsterfahrung</h1>

    <content-box
      class="contentBox"
      v-html="selbsterfahrung.selbsterfahrung"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'SelbsterfahrungEdit' }">
      BEARBEITEN
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'SelbsterfahrungIndex',
  components: {
    ContentBox,
    VButton,
    VSpacer,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('selbsterfahrung/get')
    next()
  },
  computed: {
    selbsterfahrung: () => store.getters['selbsterfahrung/selbsterfahrung'],
  },
}
</script>
