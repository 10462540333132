<template>
  <td class="td-content" :style="css">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: 'TdContent',
  props: {
    css: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.td-content {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  line-height: 1.2;
}
</style>
