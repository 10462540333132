<template>
  <div>
    <GoBack :to="{ name: 'UeberMichIndex' }">Über Mich</GoBack>

    <h1>Ausbildung bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="jahre"
          label="Zeitraum"
          fieldWidth="32rem"
          v-model="formData.jahre"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldTextarea
          field="ausbildung"
          label="Ausbildung*"
          fieldWidth="32rem"
          :rows="3"
          v-model="formData.ausbildung"
          :errors="formErrors"
          :rules="validationRules"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'UeberMichIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>

      <FormRowButtons>
        <VButton type="button" mode="danger" @click="$refs.deleteModal.open()">
          LÖSCHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <ModalConfirm ref="deleteModal" @confirm="deleteAusbildung">
      Sind Sie sicher, dass Sie diese Ausbildung löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/ausbildungen'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import ModalConfirm from '@/components/global/ModalConfirm'

export default {
  name: 'AusbildungenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    ModalConfirm,
  },
  props: ['ausbildungId'],
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('ausbildungen/getOne', to.params.ausbildungId)
    next()
  },
  created() {
    this.formData = {
      jahre: this.ausbildung.jahre ?? '',
      ausbildung: this.ausbildung.ausbildung,
    }
  },
  computed: {
    ausbildung: () => store.getters['ausbildungen/one'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('ausbildungen/update', {
        id: this.ausbildungId,
        formData: this.formData,
      })
      this.$router.push({ name: 'UeberMichIndex' })
    },
    async deleteAusbildung() {
      await this.$store.dispatch('ausbildungen/delete', this.ausbildungId)
      this.$router.push({ name: 'UeberMichIndex' })
    },
  },
}
</script>
