import axios from 'axios'

const getDefaultState = () => ({
  all: [],
  one: {},
})

const state = getDefaultState()

const getters = {
  all: state => state.all,
  one: state => state.one,
}

const mutations = {
  SET_ALL: (state, all) => (state.all = all),

  SET_ONE: (state, one) => (state.one = one),

  SET_POSITION: (state, { id, position }) => {
    const index = state.all.findIndex(one => one.id == id)
    const removed = state.all.splice(index, 1)[0]
    state.all.splice(position - 1, 0, removed)
    let order = 1
    state.all.forEach(one => (one.order = order++))
  },
}

const actions = {
  getAll: async ({ commit }) => {
    const url = '/kooperationspartner'
    const res = await axios.get(url)
    commit('SET_ALL', res.data.data)
    return res.data.data
  },

  getOne: async ({ commit }, id) => {
    const url = `/kooperationspartner/${id}`
    const res = await axios.get(url)
    commit('SET_ONE', res.data.data)
    return res.data.data
  },

  create: async ({ dispatch }, formData) => {
    try {
      const url = '/kooperationspartner'
      const res = await axios.post(url, formData)
      dispatch('flashMessage/flashGespeichert', null, { root: true })
      return res.data.data
    } catch (err) {
      dispatch('flashMessage/flashSpeichernFehlgeschlagen', null, { root: true })
      throw err.response.data
    }
  },

  update: async ({ commit, dispatch }, { id, formData }) => {
    try {
      const url = `/kooperationspartner/${id}`
      const res = await axios.patch(url, formData)
      commit('SET_ONE', res.data.data)
      dispatch('flashMessage/flashGespeichert', null, { root: true })
      return res.data.data
    } catch (err) {
      dispatch('flashMessage/flashSpeichernFehlgeschlagen', null, { root: true })
      throw err.response.data
    }
  },

  delete: async ({ dispatch }, id) => {
    try {
      const url = `/kooperationspartner/${id}`
      const res = await axios.delete(url)
      dispatch('flashMessage/flashGelöscht', null, { root: true })
      return res.data.data
    } catch (err) {
      dispatch('flashMessage/flashLöschenFehlgeschlagen', null, { root: true })
      throw err.response.data
    }
  },

  setPosition: async ({ commit, dispatch }, payload) => {
    commit('SET_POSITION', payload)
    try {
      const url = `/kooperationspartner/${payload.id}/set-position`
      const data = { position: payload.position }
      const res = await axios.post(url, data)
      return res.data.data
    } catch (err) {
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Reihenfolge konnte nicht geändert werden - Bitte Seite aktualisieren',
          mode: 'bad',
          duration: 10000,
        },
        { root: true }
      )
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
