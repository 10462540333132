import ListTable from './ListTable'
import DetailsTable from './DetailsTable'
import TableButton from './TableButton'
import TableButtonEdit from './TableButtonEdit'
import TableButtonInspect from './TableButtonInspect'
import TableButtonDelete from './TableButtonDelete'
import TbodyDraggable from './TbodyDraggable'
import TableOrder from './TableOrder'
import TableAddItem from './TableAddItem'
import TdLink from './TdLink'
import TdContent from './TdContent'
import TdButtons from './TdButtons'
import TableLink from './TableLink'
import TableToggle from './TableToggle'
import OrderToggle from './OrderToggle'

export default {
  ListTable,
  TableButton,
  TableButtonEdit,
  TableButtonInspect,
  TableButtonDelete,
  TbodyDraggable,
  TableOrder,
  TableAddItem,
  TdLink,
  TdContent,
  TdButtons,
  TableLink,
  DetailsTable,
  TableToggle,
  OrderToggle,
}
