<template>
  <div class="cms" :style="`max-width: ${maxWidth}`"></div>
</template>

<script>
export default {
  name: 'ContentBox',
  props: {
    maxWidth: {
      type: String,
      default: '50rem',
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  padding: 1rem;
  border: $border-width solid $border-col;
  border-radius: 8px;
}
</style>
