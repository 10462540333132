var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GoBack',{attrs:{"to":{ name: 'SelbsterfahrungIndex' }}},[_vm._v("Selbsterfahrung")]),_c('h1',[_vm._v("Selbsterfahrung bearbeiten")]),_c('VForm',{on:{"submit":_vm.submit}},[_c('FormRowEditor',{attrs:{"features":[
        'bold',
        'italic',
        'underline',
        'link',
        'heading',
        'unorderedList',
        'smallprint' ]},model:{value:(_vm.formData.selbsterfahrung),callback:function ($$v) {_vm.$set(_vm.formData, "selbsterfahrung", $$v)},expression:"formData.selbsterfahrung"}}),_c('FormRowButtons',[_c('VButton',{attrs:{"type":"submit","deactivated":_vm.submitted}},[_vm._v("SPEICHERN")]),_c('VButton',{attrs:{"type":"link","to":{ name: 'SelbsterfahrungIndex' },"mode":"faint"}},[_vm._v("ABBRECHEN")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }