<template>
  <table>
    <slot></slot>
  </table>
</template>

<script>
export default {
  name: 'DetailsTable',
}
</script>

<style lang="scss" scoped>
::v-deep {
  th,
  td {
    position: relative;
    padding: 0.5rem 1rem;
    line-height: 1.2em;
  }

  tr:first-child {
    :first-child {
      border-top-left-radius: 4px;
    }

    :last-child {
      border-top-right-radius: 4px;
    }
  }

  tr:last-child {
    :first-child {
      border-bottom-left-radius: 4px;
    }

    :last-child {
      border-bottom-right-radius: 4px;
    }
  }

  th {
    height: 2.5rem;
    max-width: 12rem;
    font-weight: 700;
    letter-spacing: $tracking-wide;
    color: $col-white;
    background-color: $col-primary-500;
  }

  td {
    height: 2rem;
    max-width: 32rem;
    background-color: $col-white;
  }
}
</style>
