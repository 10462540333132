<template>
  <div>
    <GoBack :to="{ name: 'SpezialisierungenIndex' }">Spezialisierungen</GoBack>

    <h1>Spezialisierung bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="titel"
          label="Spezialisierung*"
          fieldWidth="32rem"
          v-model="formData.titel"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormRowFields>
          <FormLabelInline width="24rem" style="margin-top: 4.5rem" for="beschreibung">
            Beschreibung*
          </FormLabelInline>
          <FormRowEditor
            v-model="formData.beschreibung"
            :features="['bold', 'italic', 'unorderedList']"
          />
        </FormRowFields>
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'SpezialisierungenIndex' }" mode="faint">
          ABBRECHEN
        </VButton>
      </FormRowButtons>

      <FormRowButtons>
        <VButton type="button" mode="danger" @click="$refs.deleteModal.open()">
          LÖSCHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <ModalConfirm ref="deleteModal" @confirm="deleteSpezialisierung">
      Sind Sie sicher, dass Sie diese Spezialisierung löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/spezialisierungen'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import ModalConfirm from '@/components/global/ModalConfirm'

export default {
  name: 'SpezialisierungenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    ModalConfirm,
  },
  props: ['spezialisierungId'],
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('spezialisierungen/getOne', to.params.spezialisierungId)
    next()
  },
  created() {
    this.formData = {
      titel: this.spezialisierung.titel,
      beschreibung: this.spezialisierung.beschreibung,
    }
  },
  computed: {
    spezialisierung: () => store.getters['spezialisierungen/one'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('spezialisierungen/update', {
        id: this.spezialisierungId,
        formData: this.formData,
      })
      this.$router.push({ name: 'SpezialisierungenIndex' })
    },
    async deleteSpezialisierung() {
      await this.$store.dispatch('spezialisierungen/delete', this.spezialisierungId)
      this.$router.push({ name: 'SpezialisierungenIndex' })
    },
  },
}
</script>
