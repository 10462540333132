<template>
  <div>
    <GoBack :to="{ name: 'UeberMichIndex' }">Über Mich</GoBack>

    <h1>Lehrtätigkeit anlegen</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldInput
          field="jahre"
          label="Zeitraum"
          fieldWidth="32rem"
          v-model="formData.jahre"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldTextarea
          field="lehrtaetigkeit"
          label="Lehrtätigkeit*"
          fieldWidth="32rem"
          :rows="3"
          v-model="formData.lehrtaetigkeit"
          :errors="formErrors"
          :rules="validationRules"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'UeberMichIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/lehrtaetigkeiten'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'LehrtaetigkeitenCreate',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: {
        jahre: '',
        lehrtaetigkeit: '',
      },
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('lehrtaetigkeiten/create', this.formData)
      this.$router.push({ name: 'UeberMichIndex' })
    },
  },
}
</script>
