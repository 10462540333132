export default {
  titel: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
    max: {
      characters: 60,
      message: {
        de: 'Der Text darf maximal 60 Zeichen lang sein.',
      },
    },
  },
  beschreibung: {
    required: {
      message: {
        de: 'Bitte füllen Sie dieses Feld aus.',
      },
    },
  },
}
