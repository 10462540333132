import MenuIcons from './Icons'

export default [
  {
    title: 'Anleitung / Hilfe',
    route: 'Dashboard',
    icon: MenuIcons.MenuHelpIcon,
  },
  {
    title: 'Home',
    route: 'HomeIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Über Mich',
    route: 'UeberMichIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Spezialisierungen',
    route: 'SpezialisierungenIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Methoden',
    route: 'MethodenIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Settings und Kosten',
    route: 'SettingsUndKostenIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Angststörungen',
    route: 'AngststoerungenIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Selbsterfahrung',
    route: 'SelbsterfahrungIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Supervision und Coaching',
    route: 'SupervisionUndCoachingIndex',
    icon: MenuIcons.MenuPageIcon,
  },
  {
    title: 'Passwort ändern',
    route: 'PasswortAendern',
    icon: MenuIcons.MenuPasswordIcon,
  },
]
