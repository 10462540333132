<template>
  <td class="td-buttons" :style="css">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: 'TdButtons',
  props: {
    css: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.td-buttons {
  white-space: nowrap;

  > *:not(:first-child) {
    margin-left: 1rem;
  }
}
</style>
