<template>
  <div>
    <GoBack :to="{ name: 'MethodenIndex' }">Methoden</GoBack>

    <h1>Methoden bearbeiten</h1>

    <VForm @submit="submit">
      <h2 class="form-heading">Einleitung</h2>
      <FormRowEditor
        v-model="formData.einleitung"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <h2 class="form-heading">Psychodrama</h2>
      <FormRowEditor
        v-model="formData.psychodrama"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <h2 class="form-heading">Hypnotherapie</h2>
      <FormRowEditor
        v-model="formData.hypnotherapie"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'MethodenIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'MethodenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: null,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('methoden/get')
    next()
  },
  created() {
    this.formData = {
      einleitung: this.methoden.einleitung,
      psychodrama: this.methoden.psychodrama,
      hypnotherapie: this.methoden.hypnotherapie,
    }
  },
  computed: {
    methoden: () => store.getters['methoden/methoden'],
  },
  methods: {
    async submit() {
      this.submitted = true
      await this.$store.dispatch('methoden/update', this.formData)
      this.$router.push({ name: 'MethodenIndex' })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-heading {
  margin-bottom: 0;
}

.form-heading:not(:first-of-type) {
  margin-top: 4rem;
}
</style>
