import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.withCredentials = true
axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response.status === 401) {
      if (store.getters['auth/isAuth']) {
        store.commit('auth/SET_UNAUTHENTICATED')
        router.push({ name: 'Login' })
      }
    } else {
      return Promise.reject(error)
    }
  }
)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
