var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Über Mich")]),_c('h2',[_vm._v("Einleitung")]),_c('content-box',{attrs:{"maxWidth":"40rem"},domProps:{"innerHTML":_vm._s(_vm.ueberMich.einleitung)}}),_c('VSpacer',{attrs:{"mode":"small"}}),_c('VButton',{attrs:{"type":"link","to":{ name: 'UeberMichEinleitungEdit' }}},[_vm._v(" BEARBEITEN ")]),_c('VSpacer'),_c('h2',[_vm._v("Berufliches")]),_c('content-box',{attrs:{"maxWidth":"40rem"},domProps:{"innerHTML":_vm._s(_vm.ueberMich.berufliches)}}),_c('VSpacer',{attrs:{"mode":"small"}}),_c('VButton',{attrs:{"type":"link","to":{ name: 'UeberMichBeruflichesEdit' }}},[_vm._v(" BEARBEITEN ")]),_c('VSpacer'),_c('h2',[_vm._v("Ausbildung")]),(_vm.ausbildungen)?_c('ListTable',[(_vm.ausbildungen.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Jahr")]),_c('th',[_vm._v("Ausbildung")]),_c('th'),_c('th',[_c('OrderIcon',{attrs:{"css":"height:2rem;fill:white;"}})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'ausbildungen')}}},[_vm._l((_vm.ausbildungen),function(ref,i){
var id = ref.id;
var jahre = ref.jahre;
var ausbildung = ref.ausbildung;
return _c('tr',{key:id},[_c('TdContent',{attrs:{"css":"min-width: 8rem"}},[_vm._v(_vm._s(jahre))]),_c('TdContent',[_vm._v(_vm._s(ausbildung))]),_c('td',[_c('TableButtonEdit',{attrs:{"to":{ name: 'AusbildungenEdit', params: { ausbildungId: id } }}},[_vm._v(" Bearbeiten ")])],1),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'ausbildungen')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'AusbildungenCreate' },"colspan":4}},[_vm._v(" Ausbildung hinzufügen ")])],1)],2)],1):_vm._e(),_c('VSpacer'),_c('h2',[_vm._v("Lehrtätigkeit")]),(_vm.lehrtaetigkeiten)?_c('ListTable',[(_vm.lehrtaetigkeiten.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Jahr")]),_c('th',[_vm._v("Lehrtätigkeit")]),_c('th'),_c('th',[_c('OrderIcon',{attrs:{"css":"height:2rem;fill:white;"}})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'lehrtaetigkeiten')}}},[_vm._l((_vm.lehrtaetigkeiten),function(ref,i){
var id = ref.id;
var jahre = ref.jahre;
var lehrtaetigkeit = ref.lehrtaetigkeit;
return _c('tr',{key:id},[_c('TdContent',{attrs:{"css":"min-width: 8rem"}},[_vm._v(_vm._s(jahre))]),_c('TdContent',[_vm._v(_vm._s(lehrtaetigkeit))]),_c('td',[_c('TableButtonEdit',{attrs:{"to":{ name: 'LehrtaetigkeitenEdit', params: { lehrtaetigkeitId: id } }}},[_vm._v(" Bearbeiten ")])],1),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'lehrtaetigkeiten')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'LehrtaetigkeitenCreate' },"colspan":4}},[_vm._v(" Lehrtätigkeit hinzufügen ")])],1)],2)],1):_vm._e(),_c('VSpacer'),_c('h2',[_vm._v("Publikationen")]),(_vm.publikationen)?_c('ListTable',[(_vm.publikationen.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Publikation")]),_c('th'),_c('th',[_c('OrderIcon',{attrs:{"css":"height:2rem;fill:white;"}})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'publikationen')}}},[_vm._l((_vm.publikationen),function(ref,i){
var id = ref.id;
var publikation = ref.publikation;
return _c('tr',{key:id},[_c('TdContent',{staticClass:"cms",domProps:{"innerHTML":_vm._s(publikation)}}),_c('td',[_c('TableButtonEdit',{attrs:{"to":{ name: 'PublikationenEdit', params: { publikationId: id } }}},[_vm._v(" Bearbeiten ")])],1),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'publikationen')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'PublikationenCreate' },"colspan":4}},[_vm._v(" Publikation hinzufügen ")])],1)],2)],1):_vm._e(),_c('VSpacer'),_c('h2',[_vm._v("Kooperationspartner")]),(_vm.kooperationspartner)?_c('ListTable',[(_vm.kooperationspartner.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Kooperationspartner")]),_c('th'),_c('th',[_c('OrderIcon',{attrs:{"css":"height:2rem;fill:white;"}})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'kooperationspartner')}}},[_vm._l((_vm.kooperationspartner),function(ref,i){
var id = ref.id;
var kooperationspartner = ref.kooperationspartner;
return _c('tr',{key:id},[_c('TdContent',{staticClass:"cms",domProps:{"innerHTML":_vm._s(kooperationspartner)}}),_c('td',[_c('TableButtonEdit',{attrs:{"to":{ name: 'KooperationspartnerEdit', params: { kooperationspartnerId: id } }}},[_vm._v(" Bearbeiten ")])],1),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'kooperationspartner')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'KooperationspartnerCreate' },"colspan":4}},[_vm._v(" Kooperationspartner hinzufügen ")])],1)],2)],1):_vm._e(),_c('VSpacer'),_c('h2',[_vm._v("Ehrenamtliche Tätigkeit")]),(_vm.ehrenamtlicheTaetigkeiten)?_c('ListTable',[(_vm.ehrenamtlicheTaetigkeiten.length > 0)?_c('thead',[_c('tr',[_c('th',[_vm._v("Ausbildung")]),_c('th'),_c('th',[_c('OrderIcon',{attrs:{"css":"height:2rem;fill:white;"}})],1)])]):_vm._e(),_c('TbodyDraggable',{on:{"end":function($event){return _vm.onOrderEnd($event, 'ehrenamtlicheTaetigkeiten')}}},[_vm._l((_vm.ehrenamtlicheTaetigkeiten),function(ref,i){
var id = ref.id;
var ehrenamtlicheTaetigkeit = ref.ehrenamtlicheTaetigkeit;
return _c('tr',{key:id},[_c('TdContent',{staticClass:"cms",domProps:{"innerHTML":_vm._s(ehrenamtlicheTaetigkeit)}}),_c('td',[_c('TableButtonEdit',{attrs:{"to":{
              name: 'EhrenamtlicheTaetigkeitenEdit',
              params: { ehrenamtlicheTaetigkeitId: id },
            }}},[_vm._v(" Bearbeiten ")])],1),_c('td',[_c('TableOrder',{attrs:{"order":i + 1},on:{"submit":function($event){return _vm.setOrder({ id: id, position: $event }, 'ehrenamtlicheTaetigkeiten')}}})],1)],1)}),_c('tr',[_c('TableAddItem',{attrs:{"to":{ name: 'EhrenamtlicheTaetigkeitenCreate' },"colspan":4}},[_vm._v(" Ehrenamtliche Tätigkeit hinzufügen ")])],1)],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }