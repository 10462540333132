<template>
  <div>
    <GoBack :to="{ name: 'HomeIndex' }">Home</GoBack>

    <h1>Home bearbeiten</h1>

    <VForm @submit="submit">
      <FormBoxFields>
        <FormFieldTextarea
          field="ueber_mich"
          label="Über Mich*"
          fieldWidth="32rem"
          :rows="6"
          v-model="formData.ueber_mich"
          :errors="formErrors"
          :rules="validationRules"
          :af="true"
        />

        <FormFieldTextarea
          field="psychotherapie"
          label="Psychotherapie*"
          fieldWidth="32rem"
          :rows="6"
          v-model="formData.psychotherapie"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="spezialisierungen"
          label="Spezialisierungen*"
          fieldWidth="32rem"
          :rows="3"
          v-model="formData.spezialisierungen"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="methoden"
          label="Methoden*"
          fieldWidth="32rem"
          :rows="3"
          v-model="formData.methoden"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="settings_und_kosten"
          label="Settings und Kosten*"
          fieldWidth="32rem"
          :rows="3"
          v-model="formData.settings_und_kosten"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="angebote"
          label="Angebote*"
          fieldWidth="32rem"
          :rows="6"
          v-model="formData.angebote"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="angststoerungen"
          label="Angststörungen*"
          fieldWidth="32rem"
          :rows="3"
          v-model="formData.angststoerungen"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="selbsterfahrung"
          label="Selbsterfahrung*"
          fieldWidth="32rem"
          :rows="3"
          v-model="formData.selbsterfahrung"
          :errors="formErrors"
          :rules="validationRules"
        />

        <FormFieldTextarea
          field="supervision_und_coaching"
          label="Supervision und Coaching*"
          fieldWidth="32rem"
          :rows="3"
          v-model="formData.supervision_und_coaching"
          :errors="formErrors"
          :rules="validationRules"
        />
      </FormBoxFields>

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'HomeIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'
import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/home'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'HomeEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: null,
      formErrors: [],
      validationRules,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('home/get')
    next()
  },
  created() {
    this.formData = {
      ueber_mich: this.home.ueberMich,
      psychotherapie: this.home.psychotherapie,
      spezialisierungen: this.home.spezialisierungen,
      methoden: this.home.methoden,
      settings_und_kosten: this.home.settingsUndKosten,
      angebote: this.home.angebote,
      angststoerungen: this.home.angststoerungen,
      selbsterfahrung: this.home.selbsterfahrung,
      supervision_und_coaching: this.home.supervisionUndCoaching,
    }
  },
  computed: {
    home: () => store.getters['home/home'],
  },
  methods: {
    ...formValidation,
    async submit() {
      this.validateAll(this.formData, this.formErrors, this.validationRules)
      if (this.formErrors.length > 0) return

      this.submitted = true
      await this.$store.dispatch('home/update', this.formData)
      this.$router.push({ name: 'HomeIndex' })
    },
  },
}
</script>
