<template>
  <div>
    <GoBack :to="{ name: 'SettingsUndKostenIndex' }">Settings und Kosten</GoBack>

    <h1>Settings und Kosten bearbeiten</h1>

    <VForm @submit="submit">
      <h2 class="form-heading">Einleitung</h2>
      <FormRowEditor
        v-model="formData.einleitung"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <h2 class="form-heading">Einzeltherapie</h2>
      <FormRowEditor
        v-model="formData.einzeltherapie"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <h2 class="form-heading">Gruppentherapie</h2>
      <FormRowEditor
        v-model="formData.gruppentherapie"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <h2 class="form-heading">Kosten</h2>
      <FormRowEditor
        v-model="formData.kosten"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'SettingsUndKostenIndex' }" mode="faint">
          ABBRECHEN
        </VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'SettingsUndKostenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: null,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('settingsUndKosten/get')
    next()
  },
  created() {
    this.formData = {
      einleitung: this.settingsUndKosten.einleitung,
      einzeltherapie: this.settingsUndKosten.einzeltherapie,
      gruppentherapie: this.settingsUndKosten.gruppentherapie,
      kosten: this.settingsUndKosten.kosten,
    }
  },
  computed: {
    settingsUndKosten: () => store.getters['settingsUndKosten/settingsUndKosten'],
  },
  methods: {
    async submit() {
      this.submitted = true
      await this.$store.dispatch('settingsUndKosten/update', this.formData)
      this.$router.push({ name: 'SettingsUndKostenIndex' })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-heading {
  margin-bottom: 0;
}

.form-heading:not(:first-of-type) {
  margin-top: 4rem;
}
</style>
