<template>
  <div>
    <GoBack :to="{ name: 'UeberMichIndex' }">Über Mich</GoBack>

    <h1>Ehrenamtliche Tätigkeit bearbeiten</h1>

    <VForm @submit="submit">
      <FormRowEditor
        v-model="formData.ehrenamtliche_taetigkeit"
        :features="['bold', 'italic', 'underline', 'link']"
      />

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'UeberMichIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>

      <FormRowButtons>
        <VButton type="button" mode="danger" @click="$refs.deleteModal.open()">
          LÖSCHEN
        </VButton>
      </FormRowButtons>
    </VForm>

    <ModalConfirm ref="deleteModal" @confirm="deleteEhrenamtlicheTaetigkeit">
      Sind Sie sicher, dass Sie diese ehrenamtliche Tätigkeit löschen wollen?
    </ModalConfirm>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'
import ModalConfirm from '@/components/global/ModalConfirm'

export default {
  name: 'EhrenamtlicheTaetigkeitenEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
    ModalConfirm,
  },
  props: ['ehrenamtlicheTaetigkeitId'],
  data() {
    return {
      formData: null,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('ehrenamtlicheTaetigkeiten/getOne', to.params.ehrenamtlicheTaetigkeitId)
    next()
  },
  created() {
    this.formData = {
      ehrenamtliche_taetigkeit: this.ehrenamtlicheTaetigkeit.ehrenamtlicheTaetigkeit,
    }
  },
  computed: {
    ehrenamtlicheTaetigkeit: () => store.getters['ehrenamtlicheTaetigkeiten/one'],
  },
  methods: {
    async submit() {
      this.submitted = true
      await this.$store.dispatch('ehrenamtlicheTaetigkeiten/update', {
        id: this.ehrenamtlicheTaetigkeitId,
        formData: this.formData,
      })
      this.$router.push({ name: 'UeberMichIndex' })
    },
    async deleteEhrenamtlicheTaetigkeit() {
      await this.$store.dispatch('ehrenamtlicheTaetigkeiten/delete', this.ehrenamtlicheTaetigkeitId)
      this.$router.push({ name: 'UeberMichIndex' })
    },
  },
}
</script>
