<template>
  <FormRowFields>
    <FormLabelInline :for="field">{{ label }}</FormLabelInline>
    <div>
      <slot></slot>
      <FormFieldError v-if="error">{{ error }}</FormFieldError>
    </div>
  </FormRowFields>
</template>

<script>
import FormRowFields from './FormRowFields'
import FormLabelInline from './FormLabelInline'
import FormFieldError from './FormFieldError'

export default {
  name: 'FormField',
  components: {
    FormRowFields,
    FormLabelInline,
    FormFieldError,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
  },
}
</script>
