import axios from 'axios'

const state = {
  authenticated: false,
  user: null,
}

const getters = {
  isAuth: state => state.authenticated,
  isGuest: state => !state.authenticated,
  user: state => state.user,
}

const mutations = {
  SET_AUTHENTICATED(state, value) {
    state.authenticated = value
  },
  SET_USER(state, value) {
    state.user = value
  },
  SET_UNAUTHENTICATED(state) {
    state.authenticated = false
    state.user = null
  },
}

const actions = {
  async login({ dispatch }, credentials) {
    await axios.get('/sanctum/csrf-cookie')
    await axios.post('/login', credentials)

    return dispatch('getUser')
  },
  async logout({ dispatch }) {
    await axios.post('/logout')

    return dispatch('getUser')
  },
  getUser({ commit }) {
    return axios
      .get('/user')
      .then(response => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data)
      })
      .catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
  },
  updatePassword: async ({ dispatch }, formData) => {
    try {
      await axios.post('/update-password', formData)
      dispatch(
        'flashMessage/flashMessage',
        {
          message: 'Passwort geändert',
          mode: 'good',
        },
        { root: true }
      )
      return true
    } catch (err) {
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
