<template>
  <div class="form-row-tiptap" :style="`max-width: ${maxw}`">
    <EditorMenuBar :editor="editor" v-slot="{ commands, isActive, focused, getMarkAttrs }">
      <div class="menubar-wrap">
        <div class="menubar" :class="{ 'is-focused': focused }">
          <button
            v-if="features.includes('bold')"
            type="button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <fett-icon>Fett</fett-icon>
          </button>

          <button
            v-if="features.includes('italic')"
            type="button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <kursiv-icon>Kursiv</kursiv-icon>
          </button>

          <button
            v-if="features.includes('underline')"
            type="button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <underline-icon>Unterstreichen</underline-icon>
          </button>

          <button
            v-if="features.includes('link')"
            type="button"
            :class="{ 'is-active': isActive.link() }"
            @click="openLinkModal(getMarkAttrs('link'))"
          >
            <link-icon>Link einfügen oder ändern</link-icon>
          </button>

          <button
            v-if="features.includes('download')"
            type="button"
            :class="{ 'is-active': isActive.download() }"
            @click="openDownloadModal(getMarkAttrs('download'))"
          >
            <download-icon>Download einfügen oder ändern</download-icon>
          </button>

          <button
            v-if="features.includes('heading')"
            type="button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            <ueberschrift-1-icon>Überschrift 1</ueberschrift-1-icon>
          </button>

          <button
            v-if="features.includes('unorderedList')"
            type="button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <bulleted-list-icon>Aufzählung</bulleted-list-icon>
          </button>

          <button
            v-if="features.includes('smallprint')"
            type="button"
            :class="{ 'is-active': isActive.smallprint() }"
            @click="commands.smallprint"
          >
            <annotation-icon>Anmerkung</annotation-icon>
          </button>

          <button
            v-if="features.includes('paragraph')"
            type="button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <paragraph-icon>Absatz</paragraph-icon>
          </button>

          <button type="button" @click="commands.undo">
            <undo-icon>Rückgängig</undo-icon>
          </button>

          <button type="button" @click="commands.redo">
            <redo-icon>Wiederherstellen</redo-icon>
          </button>
        </div>
      </div>
    </EditorMenuBar>

    <div class="editor-wrap cms">
      <EditorContent :editor="editor" />
    </div>

    <v-modal ref="linkModal" class="modal modal--link">
      <template v-slot:title>Link</template>

      <div class="row">
        <button type="button" class="remove" @click="clearLinkUrl">
          <x-icon>Link entfernen</x-icon>
        </button>
        <form @submit.prevent="setLinkUrl">
          <input ref="linkInput" v-model="linkUrl" type="text" placeholder="https://" autofocus />
        </form>
        <button type="button" class="save" @click="setLinkUrl">
          <tick-icon>Link speichern</tick-icon>
        </button>
      </div>
    </v-modal>

    <v-modal ref="downloadModal" class="modal modal--download">
      <template v-slot:title>Download</template>

      <div class="row">
        <button type="button" class="remove" @click="clearDownloadUrl">
          <x-icon>Download entfernen</x-icon>
        </button>
        <form @submit.prevent="setDownloadUrl">
          <input ref="downloadInput" v-model="downloadUrl" type="text" placeholder="//" autofocus />
        </form>
        <button type="button" class="save" @click="setDownloadUrl">
          <tick-icon>Download speichern</tick-icon>
        </button>
      </div>
    </v-modal>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Italic,
  Bold,
  Underline,
  Heading,
  ListItem,
  BulletList,
  HardBreak,
  History,
} from 'tiptap-extensions'
import Link from '@/tiptap-custom-extensions/CustomLink'
import Download from '@/tiptap-custom-extensions/Download'
import Smallprint from '@/tiptap-custom-extensions/Smallprint'

import Modal from '@/components/global/Modal'

import XIcon from '@/components/cmsIcons/XIcon'
import TickIcon from '@/components/cmsIcons/TickIcon'
import {
  FettIcon,
  KursivIcon,
  UnderlineIcon,
  LinkIcon,
  DownloadIcon,
  Ueberschrift1Icon,
  BulletedListIcon,
  AnnotationIcon,
  ParagraphIcon,
  UndoIcon,
  RedoIcon,
} from '@/components/EditorIcons'

export default {
  name: 'FormTipTap',
  components: {
    EditorContent,
    EditorMenuBar,
    'v-modal': Modal,
    XIcon,
    FettIcon,
    KursivIcon,
    UnderlineIcon,
    LinkIcon,
    DownloadIcon,
    BulletedListIcon,
    Ueberschrift1Icon,
    AnnotationIcon,
    ParagraphIcon,
    UndoIcon,
    RedoIcon,
    TickIcon,
  },
  props: {
    value: {
      type: String,
      default: '...',
    },
    maxw: {
      type: String,
      default: '50rem',
    },
    features: {
      type: Array,
      default: () => ['bold', 'italic', 'underline'],
    },
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Bold(),
          new Italic(),
          new Underline(),
          new Link(),
          new Download(),
          new BulletList(),
          new ListItem(),
          new Heading({ levels: [3] }),
          new Smallprint(),
          new HardBreak(),
          new History(),
        ],
        content: this.value,
        onUpdate: ({ getHTML }) => {
          const body = getHTML()
          this.$emit('input', body)
        },
      }),
      linkUrl: null,
      linkMenuIsActive: false,
      downloadUrl: null,
      downloadMenuIsActive: false,
      autosaver: null,
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    openLinkModal(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$refs.linkModal.open()
    },
    closeLinkModal() {
      this.linkUrl = null
      this.linkMenuIsActive = false
      this.$refs.linkModal.close()
    },
    setLinkUrl() {
      this.editor.commands.link({ href: this.linkUrl })
      this.closeLinkModal()
    },
    clearLinkUrl() {
      this.linkUrl = null
    },
    openDownloadModal(attrs) {
      this.downloadUrl = attrs.href
      this.downloadMenuIsActive = true
      this.$refs.downloadModal.open()
    },
    closeDownloadModal() {
      this.downloadUrl = null
      this.downloadMenuIsActive = false
      this.$refs.downloadModal.close()
    },
    setDownloadUrl() {
      this.editor.commands.download({ href: this.downloadUrl })
      this.closeDownloadModal()
    },
    clearDownloadUrl() {
      this.downloadUrl = null
    },
  },
}
</script>

<style lang="scss" scoped>
.form-row-tiptap {
  width: 100%;
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }
}

.menubar {
  button {
    display: inline-block;
    height: 2rem;
    border-radius: 0.375rem;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 0.25rem;
    }

    &:hover {
      background: $col-gray-200;
    }

    &.is-active {
      background: $col-gray-300;
    }
  }
}

.editor-wrap {
  margin-top: 0.5rem;
  min-height: 4rem;
  padding: 0.5rem;
  border: 1px solid $col-gray-200;
  border-radius: 0.25rem;
  background: $col-white;
  font-size: 100%;
  transition: all ease 250ms;
}

/deep/ span.more {
  position: relative;
  background: #e8e8e8;
  box-shadow: 0px 4px 0px 0px #e8e8e8, 0px -4px 0px 0px #e8e8e8;
}

.modal--link,
.modal--download {
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  form {
    display: inline-block;
  }

  input {
    height: 2rem;
    width: 16rem;
    padding: 0 0.5rem;
    line-height: 2rem;
    border-top: 1px solid $col-gray-300;
    border-bottom: 1px solid $col-gray-300;

    &::placeholder {
      color: $col-gray-400;
    }
  }

  button {
    height: 2rem;

    svg {
      fill: $col-white;
      height: 2rem;
    }

    &.remove {
      border-radius: 0.25rem 0 0 0.25rem;
      background: $col-gray-300;

      &:hover,
      &:focus {
        background: $col-gray-500;
      }
    }

    &.save {
      border-radius: 0 0.25rem 0.25rem 0;
      background: $col-primary-500;

      &:hover,
      &:focus {
        background: $col-primary-600;
      }
    }
  }
}
</style>
