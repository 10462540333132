<template>
  <div>
    <h1>Settings und Kosten</h1>

    <h2>Einleitung</h2>

    <content-box
      class="contentBox"
      v-html="settingsUndKosten.einleitung"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <h2>Einzeltherapie</h2>

    <content-box
      class="contentBox"
      v-html="settingsUndKosten.einzeltherapie"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <h2>Gruppentherapie</h2>

    <content-box
      class="contentBox"
      v-html="settingsUndKosten.gruppentherapie"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <h2>Kosten</h2>

    <content-box
      class="contentBox"
      v-html="settingsUndKosten.kosten"
      maxWidth="40rem"
    ></content-box>

    <VSpacer mode="small" />

    <VButton type="link" :to="{ name: 'SettingsUndKostenEdit' }">
      BEARBEITEN
    </VButton>
  </div>
</template>

<script>
import store from '@/store'

import ContentBox from '@/components/global/ContentBox'
import VButton from '@/components/global/Button'
import VSpacer from '@/components/global/Spacer'

export default {
  name: 'SettingsUndKostenIndex',
  components: {
    ContentBox,
    VButton,
    VSpacer,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('settingsUndKosten/get')
    next()
  },
  computed: {
    settingsUndKosten: () => store.getters['settingsUndKosten/settingsUndKosten'],
  },
}
</script>
