export default {
  password_current: {
    required: {
      message: {
        de: 'Bitte geben Sie Ihr aktuelles Passwort an.',
      },
    },
  },
  password_new: {
    required: {
      message: {
        de: 'Bitte geben Sie ein Passwort an.',
      },
    },
    min: {
      characters: 8,
      message: {
        de: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
      },
    },
  },
  password_repeat: {
    required: {
      message: {
        de: 'Bitte geben Sie ein Passwort an.',
      },
    },
  },
}
