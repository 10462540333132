import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Auth from '../layouts/Auth.vue'
import Guest from '../layouts/Guest.vue'

import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import PageNotFound from '../views/PageNotFound.vue'
import PasswortAendern from '../views/PasswortAendern.vue'

import HomeIndex from '../views/Home/Index.vue'
import HomeEdit from '../views/Home/Edit.vue'

import UeberMichIndex from '../views/UeberMich/Index.vue'
import UeberMichEinleitungEdit from '../views/UeberMich/EinleitungEdit.vue'
import UeberMichBeruflichesEdit from '../views/UeberMich/BeruflichesEdit.vue'

import AusbildungenCreate from '../views/UeberMich/Ausbildungen/Create.vue'
import AusbildungenEdit from '../views/UeberMich/Ausbildungen/Edit.vue'

import LehrtaetigkeitenCreate from '../views/UeberMich/Lehrtaetigkeiten/Create.vue'
import LehrtaetigkeitenEdit from '../views/UeberMich/Lehrtaetigkeiten/Edit.vue'

import PublikationenCreate from '../views/UeberMich/Publikationen/Create.vue'
import PublikationenEdit from '../views/UeberMich/Publikationen/Edit.vue'

import KooperationspartnerCreate from '../views/UeberMich/Kooperationspartner/Create.vue'
import KooperationspartnerEdit from '../views/UeberMich/Kooperationspartner/Edit.vue'

import EhrenamtlicheTaetigkeitenCreate from '../views/UeberMich/EhrenamtlicheTaetigkeiten/Create.vue'
import EhrenamtlicheTaetigkeitenEdit from '../views/UeberMich/EhrenamtlicheTaetigkeiten/Edit.vue'

import SpezialisierungenIndex from '../views/Spezialisierungen/Index.vue'
import SpezialisierungenCreate from '../views/Spezialisierungen/Create.vue'
import SpezialisierungenEdit from '../views/Spezialisierungen/Edit.vue'

import MethodenIndex from '../views/Methoden/Index.vue'
import MethodenEdit from '../views/Methoden/Edit.vue'

import SettingsUndKostenIndex from '../views/SettingsUndKosten/Index.vue'
import SettingsUndKostenEdit from '../views/SettingsUndKosten/Edit.vue'

import AngststoerungenIndex from '../views/Angststoerungen/Index.vue'
import AngststoerungenEdit from '../views/Angststoerungen/Edit.vue'

import SelbsterfahrungIndex from '../views/Selbsterfahrung/Index.vue'
import SelbsterfahrungEdit from '../views/Selbsterfahrung/Edit.vue'

import SupervisionUndCoachingIndex from '../views/SupervisionUndCoaching/Index.vue'
import SupervisionUndCoachingEdit from '../views/SupervisionUndCoaching/Edit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Auth,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/passwort-aendern',
        name: 'PasswortAendern',
        component: PasswortAendern,
      },
      {
        path: '/home',
        name: 'HomeIndex',
        component: HomeIndex,
      },
      {
        path: '/home/bearbeiten',
        name: 'HomeEdit',
        component: HomeEdit,
      },
      {
        path: '/ueber-mich',
        name: 'UeberMichIndex',
        component: UeberMichIndex,
      },
      {
        path: '/ueber-mich/einleitung/bearbeiten',
        name: 'UeberMichEinleitungEdit',
        component: UeberMichEinleitungEdit,
      },
      {
        path: '/ueber-mich/berufliches/bearbeiten',
        name: 'UeberMichBeruflichesEdit',
        component: UeberMichBeruflichesEdit,
      },
      {
        path: '/ueber-mich/ausbildung/neu',
        name: 'AusbildungenCreate',
        component: AusbildungenCreate,
      },
      {
        path: '/ueber-mich/ausbildung/:ausbildungId/bearbeiten',
        name: 'AusbildungenEdit',
        component: AusbildungenEdit,
        props: true,
      },
      {
        path: '/ueber-mich/lehrtaetigkeit/neu',
        name: 'LehrtaetigkeitenCreate',
        component: LehrtaetigkeitenCreate,
      },
      {
        path: '/ueber-mich/lehrtaetigkeit/:lehrtaetigkeitId/bearbeiten',
        name: 'LehrtaetigkeitenEdit',
        component: LehrtaetigkeitenEdit,
        props: true,
      },
      {
        path: '/ueber-mich/publikationen/neu',
        name: 'PublikationenCreate',
        component: PublikationenCreate,
      },
      {
        path: '/ueber-mich/publikationen/:publikationId/bearbeiten',
        name: 'PublikationenEdit',
        component: PublikationenEdit,
        props: true,
      },
      {
        path: '/ueber-mich/kooperationspartner/neu',
        name: 'KooperationspartnerCreate',
        component: KooperationspartnerCreate,
      },
      {
        path: '/ueber-mich/kooperationspartner/:kooperationspartnerId/bearbeiten',
        name: 'KooperationspartnerEdit',
        component: KooperationspartnerEdit,
        props: true,
      },
      {
        path: '/ueber-mich/ehrenamtliche-taetigkeit/neu',
        name: 'EhrenamtlicheTaetigkeitenCreate',
        component: EhrenamtlicheTaetigkeitenCreate,
      },
      {
        path: '/ueber-mich/ehrenamtliche-taetigkeit/:ehrenamtlicheTaetigkeitId/bearbeiten',
        name: 'EhrenamtlicheTaetigkeitenEdit',
        component: EhrenamtlicheTaetigkeitenEdit,
        props: true,
      },
      {
        path: '/spezialisierungen',
        name: 'SpezialisierungenIndex',
        component: SpezialisierungenIndex,
      },
      {
        path: '/spezialisierungen/neu',
        name: 'SpezialisierungenCreate',
        component: SpezialisierungenCreate,
      },
      {
        path: '/spezialisierungen/:spezialisierungId/bearbeiten',
        name: 'SpezialisierungenEdit',
        component: SpezialisierungenEdit,
        props: true,
      },
      {
        path: '/methoden',
        name: 'MethodenIndex',
        component: MethodenIndex,
      },
      {
        path: '/methoden/bearbeiten',
        name: 'MethodenEdit',
        component: MethodenEdit,
      },
      {
        path: '/settings-und-kosten',
        name: 'SettingsUndKostenIndex',
        component: SettingsUndKostenIndex,
      },
      {
        path: '/settings-und-kosten/bearbeiten',
        name: 'SettingsUndKostenEdit',
        component: SettingsUndKostenEdit,
      },
      {
        path: '/angststoerungen',
        name: 'AngststoerungenIndex',
        component: AngststoerungenIndex,
      },
      {
        path: '/angststoerungen/bearbeiten',
        name: 'AngststoerungenEdit',
        component: AngststoerungenEdit,
      },
      {
        path: '/selbsterfahrung',
        name: 'SelbsterfahrungIndex',
        component: SelbsterfahrungIndex,
      },
      {
        path: '/selbsterfahrung/bearbeiten',
        name: 'SelbsterfahrungEdit',
        component: SelbsterfahrungEdit,
      },
      {
        path: '/supervision-und-coaching',
        name: 'SupervisionUndCoachingIndex',
        component: SupervisionUndCoachingIndex,
      },
      {
        path: '/supervision-und-coaching/bearbeiten',
        name: 'SupervisionUndCoachingEdit',
        component: SupervisionUndCoachingEdit,
      },
    ],
  },
  {
    path: '/',
    component: Guest,
    meta: { requiresGuest: true },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
    ],
  },
  {
    path: '*',
    component: Auth,
    children: [
      {
        path: '',
        name: '404',
        component: PageNotFound,
        beforeEnter: (to, from, next) => {
          if (store.getters['auth/isAuth']) next()
          else next({ name: 'Login' })
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/getUser')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuth']) next({ name: 'Login' })
    else next()
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (store.getters['auth/isAuth']) next({ name: 'Dashboard' })
    else next()
  } else {
    next()
  }
})

export default router
