<template>
  <div>
    <h1>Anleitung / Hilfe</h1>

    <article class="cms">
      <h2>Listen</h2>
      <!-- prettier-ignore -->
      <p>Manche Inhalte deiner Website sind Aufzählungen (bspw. die <em>Spezialisierungen</em>). Diese werden im CMS als sortierbare Listen wie diese angezeigt:</p>
      <p></p>
      <ListTable>
        <thead>
          <tr>
            <th>Spezialisierungen</th>
            <th></th>
            <th><OrderIcon css="height:2rem;fill:white;" /></th>
          </tr>
        </thead>
        <TbodyDraggable>
          <tr v-for="({ id, titel }, i) in spezialisierungen" :key="id">
            <TdContent>{{ titel }}</TdContent>
            <td>
              <TableButtonEdit :to="{ name: 'Dashboard' }">Bearbeiten</TableButtonEdit>
            </td>
            <td><TableOrder :order="i + 1" /></td>
          </tr>
          <tr>
            <TableAddItem :to="{ name: 'SpezialisierungenCreate' }" :colspan="3">
              Spezialisierung hinzufügen
            </TableAddItem>
          </tr>
        </TbodyDraggable>
      </ListTable>
      <p></p>
      <!-- prettier-ignore -->
      <p>Um einen neuen Listen-Eintrag <strong>hinzuzufügen</strong>, bitte auf den entsprechenden Button am unteren Ende der Liste klicken.</p>
      <p></p>
      <!-- prettier-ignore -->
      <p>Um einen Listen-Eintrag zu <strong>bearbeiten</strong>, bitte auf den Stift <span class="showcase-table-icon"><TableButtonEdit :to="{ name: 'Dashboard' }" /></span> klicken.</p>
      <p></p>
      <!-- prettier-ignore -->
      <p>Um einen Listen-Eintrag zu <strong>entfernen</strong>, bitte ebenfalls auf den Stift <span class="showcase-table-icon"><TableButtonEdit :to="{ name: 'Dashboard' }" /></span> klicken. Am Ende der Bearbeiten-Seite gibt es einen „Löschen“-Button.</p>
      <p></p>
      <!-- prettier-ignore -->
      <p>Die <strong>Reihenfolge</strong> der Einträge kann auf zwei Wegen verändert werden:</p>
      <ul>
        <li>
          Du kannst in das Positions-Feld die gewünschte Zielposition eintragen und mit der
          Eingabe-Taste bestätigen.
        </li>
        <!-- prettier-ignore -->
        <li>Du kannst den Eintrag am Verschiebe-Griff <span class="showcase-table-icon"><DragHandle /></span> „nehmen“ und an die gewünschte Position ziehen.</li>
      </ul>
      <p></p>
      <p>In beiden Fällen wird die neue Position automatisch sofort gespeichert.</p>

      <h2>Formulare</h2>
      <p>Alle Inhalte werden über Formulare wie dieses bearbeitet:</p>
      <p></p>
      <VForm @submit.prevent>
        <FormBoxFields>
          <FormFieldInput
            field="titel"
            label="Spezialisierung*"
            fieldWidth="32rem"
            v-model="formData.titel"
          />
          <FormRowFields>
            <FormLabelInline width="24rem" style="margin-top: 4.5rem" for="beschreibung">
              Beschreibung*
            </FormLabelInline>
            <FormRowEditor
              v-model="formData.beschreibung"
              :features="['bold', 'italic', 'unorderedList']"
            />
          </FormRowFields>
        </FormBoxFields>
        <FormRowButtons>
          <VButton type="submit">SPEICHERN</VButton>
          <VButton type="button" mode="faint">
            ABBRECHEN
          </VButton>
        </FormRowButtons>
      </VForm>
      <p></p>
      <p>
        Mit einem Stern * markierte Felder sind Pflichtfelder. Alle anderen Felder sind optional.
      </p>
      <p></p>
      <p>
        Bei manchen Feldern kannst du nicht nur Text eintragen, sondern diesen auch formatieren (wie
        bspw. oben im Feld „Beschreibung“). Die Buttons bedeuten:
      </p>
      <!-- prettier-ignore -->
      <p><span class="showcase-editor-icon"><FettIcon /></span>fett</p>
      <!-- prettier-ignore -->
      <p><span class="showcase-editor-icon"><KursivIcon /></span>kursiv</p>
      <!-- prettier-ignore -->
      <p><span class="showcase-editor-icon"><UnderlineIcon /></span>unterstrichen</p>
      <!-- prettier-ignore -->
      <p><span class="showcase-editor-icon"><LinkIcon /></span>Link</p>
      <!-- prettier-ignore -->
      <p><span class="showcase-editor-icon"><BulletedListIcon /></span>Aufzählung</p>
      <!-- prettier-ignore -->
      <p><span class="showcase-editor-icon"><Ueberschrift1Icon /></span>(kleine) Zwischenüberschrift</p>
      <!-- prettier-ignore -->
      <p><span class="showcase-editor-icon"><AnnotationIcon /></span>klein gedruckte Anmerkung (Fußnote)</p>
      <!-- prettier-ignore -->
      <p><span class="showcase-editor-icon"><UndoIcon /></span>rückgängig machen</p>
      <!-- prettier-ignore -->
      <p><span class="showcase-editor-icon"><RedoIcon /></span>wiederherstellen</p>
    </article>
  </div>
</template>

<script>
import store from '@/store'

import TableComponents from '@/components/TableComponents'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

import DragHandle from '@/components/cmsIcons/DragHandle'
import OrderIcon from '@/components/cmsIcons/OrderIcon'
import {
  FettIcon,
  KursivIcon,
  UnderlineIcon,
  LinkIcon,
  Ueberschrift1Icon,
  BulletedListIcon,
  AnnotationIcon,
  UndoIcon,
  RedoIcon,
} from '@/components/EditorIcons'

export default {
  name: 'Dashboard',
  components: {
    ...TableComponents,
    ...FormComponents,
    VButton,
    DragHandle,
    OrderIcon,
    FettIcon,
    KursivIcon,
    UnderlineIcon,
    LinkIcon,
    BulletedListIcon,
    Ueberschrift1Icon,
    AnnotationIcon,
    UndoIcon,
    RedoIcon,
  },
  data() {
    return {
      formData: {
        titel: '',
        beschreibung: '<p></p>',
      },
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('spezialisierungen/getAll')
    next()
  },
  computed: {
    spezialisierungen: () => store.getters['spezialisierungen/all'],
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin: 4rem 0 2rem;
}

.showcase-table-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.125em;
  vertical-align: bottom;
  background-color: $col-white;
  height: 1.5em;
  width: 1.5em;
  border-radius: $border-radius;
}

.showcase-editor-icon {
  display: inline-block;
  height: 2rem;
  border-radius: 0.375rem;
  cursor: pointer;
  margin-right: 1em;
  vertical-align: baseline;
  transform: translateY(0.625rem);

  &:hover {
    background: $col-gray-200;
  }
}
</style>
