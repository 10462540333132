<template>
  <FormRowFields>
    <FormLabelInline class="label" :for="field" :width="labelWidth">
      {{ label }}
      <FormInfo v-if="info" :title="label">{{ info }}</FormInfo>
    </FormLabelInline>
    <div>
      <FormInput
        :name="field"
        :type="type"
        :ph="ph"
        :value="value"
        :rqd="rqd"
        :af="af"
        :width="fieldWidth"
        :invalid="hasError(field, errors)"
        @input="updateValue($event)"
        @keyup.native="
          hasError(field, errors) && validateField(field, $event.target.value, errors, rules[field])
        "
      />
      <!-- @blur.native="validateField(field, $event.target.value, errors, rules[field])" -->
      <FormFieldError v-if="hasError(field, errors)" :width="fieldWidth">
        {{ getErrorMessage(field, errors) }}
      </FormFieldError>
    </div>
  </FormRowFields>
</template>

<script>
import formValidation from '@/assets/js/formValidation'

import FormRowFields from './FormRowFields'
import FormLabelInline from './FormLabelInline'
import FormInput from './FormInput'
import FormFieldError from './FormFieldError'
import FormInfo from './FormInfo'

export default {
  name: 'FormFieldInput',
  components: {
    FormRowFields,
    FormLabelInline,
    FormInput,
    FormFieldError,
    FormInfo,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'text',
    },
    ph: {
      type: String,
      default: '',
    },
    rqd: {
      type: Boolean,
      default: false,
    },
    af: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: '16rem',
    },
    fieldWidth: {
      type: String,
      default: '16rem',
    },
    info: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...formValidation,
    updateValue(value) {
      this.$emit('input', value)
    },
  },
}
</script>
