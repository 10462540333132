<template>
  <div>
    <GoBack :to="{ name: 'SelbsterfahrungIndex' }">Selbsterfahrung</GoBack>

    <h1>Selbsterfahrung bearbeiten</h1>

    <VForm @submit="submit">
      <FormRowEditor
        v-model="formData.selbsterfahrung"
        :features="[
          'bold',
          'italic',
          'underline',
          'link',
          'heading',
          'unorderedList',
          'smallprint',
        ]"
      />

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'SelbsterfahrungIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'SelbsterfahrungEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: null,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('selbsterfahrung/get')
    next()
  },
  created() {
    this.formData = {
      selbsterfahrung: this.selbsterfahrung.selbsterfahrung,
    }
  },
  computed: {
    selbsterfahrung: () => store.getters['selbsterfahrung/selbsterfahrung'],
  },
  methods: {
    async submit() {
      this.submitted = true
      await this.$store.dispatch('selbsterfahrung/update', this.formData)
      this.$router.push({ name: 'SelbsterfahrungIndex' })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-heading {
  margin-bottom: 0;
}

.form-heading:not(:first-of-type) {
  margin-top: 4rem;
}
</style>
