<template>
  <div>
    <GoBack :to="{ name: 'UeberMichIndex' }">Über Mich</GoBack>

    <h1>Über Mich: Berufliches bearbeiten</h1>

    <VForm @submit="submit">
      <FormRowEditor
        v-model="formData.berufliches"
        :features="['bold', 'italic', 'underline', 'link', 'unorderedList']"
      />

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'UeberMichIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import store from '@/store'

import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'UeberMichBeruflichesEdit',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: null,
      submitted: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('ueberMich/get')
    next()
  },
  created() {
    this.formData = {
      berufliches: this.ueberMich.berufliches,
    }
  },
  computed: {
    ueberMich: () => store.getters['ueberMich/ueberMich'],
  },
  methods: {
    async submit() {
      this.submitted = true
      await this.$store.dispatch('ueberMich/update', this.formData)
      this.$router.push({ name: 'UeberMichIndex' })
    },
  },
}
</script>
