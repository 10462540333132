<template>
  <div>
    <GoBack :to="{ name: 'UeberMichIndex' }">Über Mich</GoBack>

    <h1>Kooperationspartner anlegen</h1>

    <VForm @submit="submit">
      <FormRowEditor
        v-model="formData.kooperationspartner"
        :features="['bold', 'italic', 'link']"
      />

      <FormRowButtons>
        <VButton type="submit" :deactivated="submitted">SPEICHERN</VButton>
        <VButton type="link" :to="{ name: 'UeberMichIndex' }" mode="faint">ABBRECHEN</VButton>
      </FormRowButtons>
    </VForm>
  </div>
</template>

<script>
import GoBack from '@/components/global/GoBack'
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/global/Button'

export default {
  name: 'KooperationspartnerCreate',
  components: {
    GoBack,
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: {
        kooperationspartner: '<p></p>',
      },
      submitted: false,
    }
  },
  methods: {
    async submit() {
      this.submitted = true
      await this.$store.dispatch('kooperationspartner/create', this.formData)
      this.$router.push({ name: 'UeberMichIndex' })
    },
  },
}
</script>
