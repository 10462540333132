import axios from 'axios'

const getDefaultState = () => ({
  supervisionUndCoaching: {},
})

const state = getDefaultState()

const getters = {
  supervisionUndCoaching: state => state.supervisionUndCoaching,
}

const mutations = {
  SET: (state, supervisionUndCoaching) => (state.supervisionUndCoaching = supervisionUndCoaching),
}

const actions = {
  get: async ({ commit }) => {
    const url = '/supervision-und-coaching'
    const res = await axios.get(url)
    commit('SET', res.data.data)
    return res.data.data
  },

  update: async ({ commit, dispatch }, formData) => {
    try {
      const url = '/supervision-und-coaching'
      const res = await axios.patch(url, formData)
      commit('SET', res.data.data)
      dispatch('flashMessage/flashGespeichert', null, { root: true })
      return res.data.data
    } catch (err) {
      dispatch('flashMessage/flashSpeichernFehlgeschlagen', null, { root: true })
      throw err.response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
