<template>
  <svg viewBox="0 0 64 64" :style="css">
    <title>
      <slot>Order</slot>
    </title>
    <path
      d="M50.69,23.33,42.83,11.59a.93.93,0,0,0-1.66,0L33.31,23.33c-.66,1-.19,2.67.74,2.67h5V48.94A1.05,1.05,0,0,0,40.06,50h3.88A1.05,1.05,0,0,0,45,48.94V26h5C50.88,26,51.35,24.31,50.69,23.33Z"
    />
    <path
      d="M30,38H25V15.06A1.05,1.05,0,0,0,23.94,14H20.06A1.05,1.05,0,0,0,19,15.06V38H14.05c-.93,0-1.4,1.69-.74,2.67l7.86,11.74a.93.93,0,0,0,1.66,0l7.86-11.74C31.35,39.69,30.88,38,30,38Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrderIcon',
  props: {
    css: {
      type: String,
      default: null,
    },
  },
}
</script>
